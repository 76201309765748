import React, { useRef, useState, useEffect } from 'react';
import AppAppBar from './views/AppAppBar';
import Box from '@mui/material/Box';
import { useTrail, animated } from '@react-spring/web';
import Typography from './components/Typography';
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import backgroundImage from '../../assets/offWhite.jpg';


const focusAreas = [
	{ 
		"tag": "#essaycraft",
		"title": en.marketing.aboutSection.EssayCraftTitle,
		"description": en.marketing.aboutSection.EssayCraftDescription
	},
	{
		"tag": "#versecanvas",
		"title": en.marketing.aboutSection.VerseCanvasTitle,
		"description": en.marketing.aboutSection.VerseCanvasDescription
	},
	{
		"tag": "#inklink",
		"title": en.marketing.aboutSection.InkLinkTitle,
		"description": en.marketing.aboutSection.InkLinkDescription
	},
	{
		"tag": "#inkbowl-publishing",
		"title": en.marketing.aboutSection.InkbowlPublishingTitle,
		"description": en.marketing.aboutSection.InkbowlPublishingDescription
	}
]

const About: React.FC = () => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // Array of refs for each section
  const [visibleSections, setVisibleSections] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleSections((prev) => ({
              ...prev,
              [entry.target.id]: true,
            }));
          }
        });
      },
      { threshold: 0.5 } // Adjust visibility threshold as needed
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <React.Fragment>
      <AppAppBar />
      <Box sx={{ 
        p: 10, pt: 20,
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.45),rgba(255, 255, 255, 0.45)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        {focusAreas.map((text, index) => {
          const isVisible = visibleSections[text.tag];
          const trail = useTrail(focusAreas.length, {
            opacity: isVisible ? 1 : 0,
            x: isVisible ? 0 : 20,
            from: { opacity: 0, x: 20, height: 0 },
            config: { mass: 5, tension: 2000, friction: 200 },
          });

          return (
            <animated.div
              key={index}
              id={text.tag} // Anchor point
              ref={(el) => (sectionRefs.current[index] = el)} // Assign ref
              style={{
                ...trail[index],
                padding: '10px',
                height: '80vh',
              }}
            >
              <Typography component="a" variant="h1">
                {text.title}
              </Typography>

              <Typography variant="h5" sx={{mt: 2, mb: 2, whiteSpace: "pre-line"}}>
                {text.description}
              </Typography>
            </animated.div>
          );
        })}
      </Box>
    </React.Fragment>
  );
};

export default withRoot(About);
