import React, { useState, useEffect } from 'react';

import AppAppBar from './views/AppAppBar';
import AppFooter from './views/AppFooter';
import Button from './components/Button';
import EssayCraft from '../../assets/essaycraft.jpg';
import InkBowlPublishing from '../../assets/inkbowl-publishing.jpg';
import InkLink from '../../assets/inklink.jpg';
import ProductHero from './views/ProductHero';
import Typography from './components/Typography';
import VerseCnvas from '../../assets/versecanvas.jpg';
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web';

const slides = [
  {
    "title": "EssayCraft.AI",
    "subtitle": "Write for any occassion, flawlessly",
    "description": "Adapte your writing to meet diverse teacher expectations and preferences\n\nRevision suggestions tailored to each class, assignment, and purpose",
    "backgroundImage": EssayCraft,
    "link": "/about#essaycraft"
  },
  {
    "title": "InkLink",
    "subtitle": "A global stage for student authors & artists",
    "description": "Elevate your professional profile on our multimedia social network\n\nShowcase, curate a porfoilo, and connect with prospective clients",
    "backgroundImage": InkLink,
    "link": "/about#inklink"
  },
  {
    "title": "InkBowl Publishing",
    "subtitle": "Selective literature & art publishing - digitally and in-print",
    "description": "Online year-round and in our biannual multimedia magazine\n\nAcquire credentials and exposure for your work",
    "backgroundImage": InkBowlPublishing,
    "link": "/about#inkbowl-publishing"
  },
  {
    "title": "VerseCanvas.AI",
    "subtitle": "Seamless AI-generated art & literature",
    "description": "join the new wave where creativity meets innovation\n\nEnhance and redefine the possibilities of artistic expression",
    "backgroundImage": VerseCnvas,
    "link": "/about#versecanvas"
  }
]
const Main: React.FC = () => {
  const [index, set] = useState(0)
  const onClick = () => set(state => (state + 1) % 4)
  const transRef = useSpringRef()
  /* click to change slide vertically - along y axis */
  const transitions = useTransition(index, {
    ref: transRef,
    keys: index,
    from: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
  })

  useEffect(() => {
    transRef.start()
  }, [index])

  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <div style={{ height: '100vh', overflow: 'hidden' }} onClick={onClick}>
        {transitions((style, i) => (
          <animated.div
            style={{
              ...style,
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.45),rgba(255, 255, 255, 0.65)), url(${slides[i].backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              flexDirection: 'column',
              textAlign: 'center',
              padding: 2,
            }}
          >
            <Typography color="black" align="center" variant="h1" marked="center">
              {slides[i].title}
            </Typography>
            <Typography color="black" align="center" variant="h3" marked="center">
              {slides[i].subtitle}
            </Typography>
            <Typography color="black" align="center" variant="h5" marked="center" sx={{ whiteSpace: "pre-line" }}>
              {slides[i].description}
            </Typography>
            <Button size="large" variant="contained" component="a" href={slides[i].link} sx={{mt: 2}}>
              {en.marketing.learnMore}
            </Button>
          </animated.div>
        ))}
      </div>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(Main);

