import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../../../assets/ink-flow-2.gif';
import en from '../../../data/en.json';

export default function ProductHero() {
  return (
    <React.Fragment>
      <ProductHeroLayout
        alignLeft
        sxBackground={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: '#7fc7d9', // Average color of the background image.
          backgroundPosition: 'center'
        }}
      >
        {/* Increase the network loading priority of the background image. */}
        <Typography color="inherit" align="left" variant="h1" marked="left">
          {en.marketing.welcome}
        </Typography>
        <Typography
          color="inherit"
          align="left"
          variant="h4"
          sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
        >
          {en.marketing.subtitle}
        </Typography>
        <Button size="large" variant="contained" component="a" href="/dashboard">
          {en.common.signUp}
        </Button>
      </ProductHeroLayout>

      <Grid container spacing={5}>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8} display="flex" justifyContent="center">
          <Typography variant="h1" sx={{mt: 10}}>
            {en.marketing.heroContent.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} />
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{mt: 5, mb: 10, whiteSpace: "pre-line"}}>
            {en.marketing.heroContent.description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} />
      </Grid>
    </React.Fragment>
  );
}
